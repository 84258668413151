.navbar {
    position: relative;
    z-index: 900;
    height: 62px;
    width: 100vw;
    max-width: 100%;
    background-color: rgba(13, 16, 33, 0.9);
    /* rgba(14, 5, 41, 0.8);*/

}

.sticky-nav {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100%;
}

.menuContainer {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;

    height: 50px;
    align-items: center;
}

.company {

    font-size: 3rem;
    font-weight: 600;
    color: #d80650;
    padding-left: 0.5rem;
    grid-area: 1/1/1/3;
}

.company span {
    color: #d80650;
    font-size: 1.2rem;
    margin-left: -0.4rem;
}

.menu {

    display: grid;
    grid-template-columns: repeat(8, 1fr);

    z-index: 900;
    grid-area: 1/5/2/10;
}

.redMenu {
    color: var(--color-primary);
    ;
    font-size: 1.1rem;
}

.mobileMenu {

    color: #d80650;
    display: none;
}

.mobileMenu:hover {
    color: white;
    cursor: pointer;


}

/*
.menuContainer .MenuBurger {
    display: none;
    z-index: 900;
    color: var(--color-primary);
    transition: var(--transition);
    justify-self: flex-end;
    align-self: center;
    padding: 0.5rem;
    margin-top: 0.1rem;
}

.menuContainer .MenuBurger:hover {
    color: white;
    cursor: pointer;


}
*/

.pane-content {
    background-color: rgba(14, 5, 41, 0.8);
    height: fit-content;


    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: start;

    gap: 2rem;

}

.pane-content>a {
    height: 20px;
}

.menu>a {
    z-index: 905;
    font-size: 1.1rem;
}

.menu>a:hover {
    color: var(--color-primary);


}

nav a.active {

    color: var(--color-white);
    background: var(--color-bg-variant);
    padding-top: 6px;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    align-items: center;
    padding-left: 9px;


}


.searchBox {
    z-index: 900;
    grid-area: 1/9/1/10;
  



}

.whiteMenu {
    color: white
}

.right-pane-container {
    z-index: 905;
    position: absolute;
    top: 0px;

    right: 0px;
    bottom: 0px;




}

.Overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 905;

}

/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
    .company {
        grid-area: 1/1/2/6;
        font-size: 2rem;
    }


    .mobileMenu {
        display: inline-block;
        grid-area: 1/9/1/9;
        align-self: flex-start;
        margin-top: 1rem;


    }

    .mobileMenu:hover {
        color: white;
        cursor: pointer;


    }

    .searchBox {
        display: none;
    }

    .menu {
        display: none;
    }

}

/************** MEDIA QUERRY (SMALL DEVICES) ************/
@media screen and (max-width:600px) {

    .mobileMenu {
        display: inline-block;
        grid-area: 1/10/2/10;
        align-self: flex-start;
        margin-top: 1rem;


    }

    .mobileMenu:hover {
        color: white;
        cursor: pointer;


    }

    .menu {
        display: none;
    }

    .searchBox {
        display: none;
    }

    .company {
        grid-area: 1/1/2/6;
    }


}