.redBtn {
    background: red;
    z-index: 15;
    cursor: pointer;
  }

 

  .square {
    width: 90px;
    height: 70px;
   
    box-shadow: 0px 0px 1px 1px red;
    z-index: 15;
    padding:0.2rem;
    align-items: center;
    display: flex;
    align-items: center;
    
  }
 

  .pulse {
    animation: pulse-animation 2s infinite;
    z-index: 15;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(251, 5, 5, 1);
    }
    100% {
      box-shadow: 0 0 0 30px rgba(251, 5, 5, 0.6);
    }
  }