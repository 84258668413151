footer {
    background: var(--color-bg);
    padding: 3rem 0;
    text-align: center;
   
    margin-top: 1rem;
    height: fit-content;
    background-image: url("../../assets//map.jpg");
    background-size: cover;
    background-blend-mode:soft-light;
   

}


footer a {
    color: var(--color-bg);
}

.footer_logo {
   
    display: inline-block;
    width: 2rem;
    height: 2rem;
    padding: 0px;
    
    
    background: linear-gradient(var(--color-bg-variant), transparent);
    border-radius: 50%;


}
#logo_image-footer{
    margin: 1px;
   
}

footer .footer-container ul {
    display: flex;
    
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    
    width: 40%;
   
margin: 0 auto;
margin-bottom: 3rem;
}
.footer-container ul li{
    font-size: 1.2rem;
}


.footer_socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3rem;

}

.footer_socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem; 
    display: flex;
    border: 1px solid transparent
}
.footer_socials a:hover{
    background: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);
}
.footer_copyright{
    margin-bottom:3rem ;
    color: var(--color-bg);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:100%;
    border-top: 1px solid var(--color-light);
    gap: 1rem;
    padding: 1rem;


    
    
}
footer a{
    color: white;
}
footer h3{
    color: white;
}

/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
    
 }
 
 /************** MEDIA QUERRY (SMALL DEVICES) ************/
 @media screen and (max-width:600px) {
    footer .footer-container ul {
        width: 100%;
        
    }
    .footer-container ul li {
        font-size: 0.8rem;
    }

    .footer_copyright h3{
        font-size: 1rem;
       justify-content: flex-start;
    }
 }