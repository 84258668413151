.customHeader {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    overflow: visible;
    /* Add the blur effect */
    display: flex;
    flex-direction: column;

}

/*
.bg-image {
    /* The image used 
    background-image: url("../../assets/expert3.jpg");

    /* Add the blur effect 
    filter: blur(8px);
    -webkit-filter: blur(8px);

    /* Full height 
    height: 100%;

    /* Center and scale the image nicely 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}
*/


.header_container {
    text-align: center;

    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100vw;
    height: 100%;


}

.header_container .row1 {
    display: flex;
    flex-direction: row;
    flex: 2;

    max-width: 100vw;
    margin-bottom: 3rem;
}


.header_container .row1 .leftSide {
    flex: 6;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    z-index: 20;
    padding-top: 5rem;
    padding-left: 10vw;

   

}

.header_container .leftSide>h1 {
    font-size: 4rem;
    text-align: left;
}

.header_container .leftSide .vision {
    font-size: 1.4rem;
    width: 50%;
    text-align: justify;


}


.header_container .row1 .rightSide {
    display: flex;
    flex: 4;
    align-items: center;
    justify-content: center;

    z-index: 20;


}


.header_container .row2 {

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    flex: 2;

    gap: 4.5rem;
    z-index: 20;


    max-width: 100vw;



}

.card1 {
    padding: 0;
    margin: 0;
    z-index: 25;
   
}

.card2 {
    padding: 0;
    margin: 0;
    z-index: 25;
}

.headerButton {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: flex-start;


    width: 50%;

    padding: 0;


}







/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {

    .leftSide h1 {
        font-size: 3rem;
        font-weight: 600;
    }

}

/************** MEDIA QUERRY (SMALL DEVICES) ************/
@media screen and (max-width:600px) {
    .header_container .leftSide h1 {
        font-size: 2rem;
        font-weight: 600;

    }

    .header_container .leftSide {
        padding-top: 0.7rem;
        padding-bottom: 0.5rem;

    }


    .rightSide {
        display: none;


    }

    .header_container .row2 {
        grid-template-columns: 1fr;
        height: 500px;
        align-items: flex-start;
        justify-content: flex-start;

    }

    .header_container .row1 {
        flex: 0.5;
        margin-bottom: 0.5rem
    }

    .customHeader {
        height: fit-content;
        overflow: visible;
        width: 90%;

    }

    .header_container .row1 .rightSide {
        display: none;
    }

    .card1 {

        margin: 0;


    }

}

/*** End media querry */


.area {
    background: #1f1f38;
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
    width: 100%;

    background-image: url("../../assets//back2-2.jpg");
    background-size: cover;
    /* Add the blur effect */



    z-index: -1;


}


/* *************** 3 Line animation  ******************/

.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: 1;
}

.lines .line {
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    ;
    overflow: hidden;
}

.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 10px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--color-white);
    /* elevatore color*/
    -webkit-animation: moveLeftBounces-one 20s linear infinite;
    animation: moveLeftBounces-one 20s linear infinite;
}

.lines .line:nth-child(1) {
    margin-left: -25%;
}

.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

.lines .line:nth-child(3) {
    margin-left: 25%;
}


@-webkit-keyframes moveLeftBounces-one {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes moveLeftBounces-one {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

/******************* End of 3 line animation*/