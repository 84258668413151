.single-features {
    background-color: rgba(20, 15, 20, 0.9);
    padding: 30px;
    position: relative;
    z-index: 26;
    border-right: 5px solid #d2044d;
    width: 420px;
    height: 250px;
    align-items: flex-start;


}

.single-features .icon {
    color: #d2044d;
    margin-bottom: 1rem;
  
}


.single-features::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    z-index: -1;
    color: white;

}

.single-features::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0%;
    height: 100%;
    background-image: -webkit-gradient(linear,
            left top,
            right bottom,
            from(#d2044d),
            to(#ff5e68));
    background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);

    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    z-index: -1;

}

.single-features h3 {
    position: relative;
    padding-left: 45px;
    margin-bottom: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

    color: white;
    font-size: 3rem;
}

.single-features p {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 1.5rem;
}

.single-features:hover h3 {
    color: #ffffff;
}

.single-features:hover .icon {
    color: #ffffff;
}

.single-features:hover::after {
    left: 0;
    right: auto;
    width: 100%;
    color: white;
}

.cardTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

}

.cardTitle>h3 {
    color: #d2044d;
}

.cardTitle>p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.cardText {
    
    text-align: justify;
    font-size: 1.4rem;

}
/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
   
 }
 
 /************** MEDIA QUERRY (SMALL DEVICES) ************/
 @media screen and (max-width:600px) {
    .single-features {
        width: 352px;}
 }