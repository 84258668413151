.emptySpace {
   height: 3rem;


}

#contact {
   background-image: url("../../assets/contact2.jpg");
    background-size: cover;
   min-height: 100vh;

   width: 100vw;
   padding: 0;
   display: flex;
   flex-direction: column;
   
   

}
.blur-layer{
  
   height: 100%;
   width: 100%;
   
   margin: 0;
   
}
#contact h1{
   margin-top: 3rem;
   color :var(--color-white);
   padding: 1rem;
 
   
}
.contact-wrapper{
  
   display: flex;
 
   

   
}


#contact .contact-container {

   height: 85vh;
   width: 80vw;
   
   padding: 1.5rem;
   display: flex;
   align-items: flex-start;
   justify-items: center;
   flex: 1.5;
   border-left: 1px solid gray;



}

#contact .contact-details {
   flex: 1;

   height: 85vh;
   display: grid;
   grid-template-columns: 1fr;
   justify-items: center;

   padding: 1.5rem;


}
.contact-details h1{
   color:var(--color-black);
   margin: 1rem;
   
}
#contact .contact-details .contact_option {
   background: var(--color-bg-variant);
   padding: 1.2rem;

   text-align: center;
   border: 1px solid transparent;
   transition: var(--transition);
   width: 50%;
   height: 130px;
   display: flex;
   flex-direction: row;
   align-items: center;

}

.contact_option:hover {
   background: transparent;
   border-color: var(--color-primary-variant);
   color: black;

}


.contact_option .contact-option-icon {
}

.contact_option .contact-option-text {
   align-content: flex-start;
   width: 100%;
}

/*********** Form ************/

.contact-container .contact-form {
   display: grid;
   grid-template-columns: 1fr;
   width: 100%;
   height: 55%;
   align-items: center;
   justify-items: center;

}

.contact-form .send-button {
   justify-self: flex-start;
}

.contact-form .check-bots {
   display: flex;
   align-items: flex-start;
   justify-content: flex-start;
   margin-top: 1rem;
   margin-bottom: 1rem;
   width: 100%;


}

.contact-form .row1 {
   display: grid;
   grid-template-columns: 1fr 1fr;
   row-gap: 2rem;
   column-gap: 1rem;
   width: 100%;
}

.contact-form .row1 input,
.contact-form .row2 textarea {
   width: 100%;
   padding: 1.5rem;

   background: var(--color-bg3);
   border: 1px solid var(--color-primary);
   resize: vertical;
   color: var(--color-white);
   line-height: 0.8rem;
   border-color: gray;
   border-right: 5px solid var(--color-primary);

}

.contact-form .row2 {
   display: flex;
   width: 100%;
   margin-top: 2rem;
}

.contact-container form textarea {
   grid-area: 3/1/4/4;
}

.contact-container form input {
   height: 0.7rem;

   background: transparent;
  
}

/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
   .contact-form .row1 {
      grid-template-columns: 1fr;
   }

   .contact-container {
      align-self: center;
      justify-self: center;
      width: 100vw;
      height: fit-content;
   }

   #contact {
      min-height: fit-content;
   }
}

/************** MEDIA QUERRY (SMALL DEVICES) ************/
@media screen and (max-width:600px) {
   .contact-form .row1 {
      grid-template-columns: 1fr;
   }
   .contact-wrapper{
      flex-direction: column;
   }
   #contact h1{
      margin-top: 1rem;
   }

   #contact .contact-container {
      align-self: center;
      justify-self: center;
      width: 100vw;
      height: fit-content;
      border-left: 0;
   }

   #contact {
      min-height: fit-content;
      flex-direction: column;
      background-image:none
   }

   #contact .contact-details {
      gap: 0.5rem;
   }

}