.emptySpace {
    height: 3.2rem;

}

#services {
    min-height: fit-content;
    width: 100vw;
   
    background-image: url("../../assets/service6.jpg");
  background-size: cover;
  height: auto;
    

}

.services_container {
    display: flex;
    flex-direction: row;

    height: fit-content;
    backdrop-filter: blur(6px);
    height: 100%;
    

}

.services_container .picture {
    flex: 1;
    display: flex;
    flex-direction: column;


    align-items: center;
    justify-content: center;
    width: 100%;



}

.services_container .picture .image {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    transform: scaleX(1.4);




}
/*
.services_container .picture p {
    color: 'attr(param1)';
}*/

.services_container .details {
    flex: 1;
    background-color: transparent;
    padding: 2rem;
    height: fit-content;
}

.services_container .details .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding-bottom: 1rem;
    color: var(--color-white);
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/




}
.services_container .details p{
    align-self: center;
    text-align: center;
}

.services_container .details p,
ul li {
    color: var(--color-white);
    font-size: 1.2rem;
    margin-top: 1rem;

}

.services_container .details ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    justify-items:flex-start;
    padding-left: 0;
    padding-right: 0;
    


}


.services_container .details ul li {
    display: flex;
    height: 6rem;
    padding: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: var(--transition);
    padding-top: 1rem;
    font-weight: 400;
    background: var(--color-bg4);
    width: 95%;
    border-right: 5px solid var(--color-primary);
    color: var(--color-black);
    text-align: left;
    align-items: center;
   

}
.serviceTitle-line{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    width: 100%;

    gap: 1rem;
}
.serviceTitle-line .service-details{
    align-self: center;
   
}

.services_container .details ul li .icon {
    color: var(--color-primary);
    vertical-align: middle;
}

.services_container .details ul li:hover {
    transform: translateY(-5%);
    background-color: var(--color-blue-lite);
    cursor: pointer;

}


.modal-info-style h1 {
    font-size: 2.3rem;
    color: var(--color-light);
}

/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
    .services_container .picture {
        display: none;
    }

    .services_container .details ul {

        grid-template-columns: repeat(2, 1fr);

    }

}

/************** MEDIA QUERRY (SMALL DEVICES) ************/
@media screen and (max-width:600px) {
    .services_container .picture {
        display: none;
    }

    .services_container {
        height: fit-content;
    }

    .services_container .details ul {

        grid-template-columns: 1fr;

    }

    .services_container .details .title h1 {
        font-size: 2.4rem;
    }
    #services{
        background-image:none
    }

}