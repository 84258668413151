.slideButton {
    
    cursor: pointer;
    max-width: fit-content;
    width: fit-content;
    margin-top: 0.5rem;
   

    text-align: center;
    background: transparent;
}

.slideButton>a {
    display: inline-block;
    font-size: 1.1rem;
    background: #140f14;
    color: #fff;
    /*border-right: 0.25rem solid #d80650;*/
    padding: 0.85em 0.75em;
    width: 9rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.slideButton>a:hover {
    color: white;
    
}

.slideButton>a::after {
    content: "";
    background: #d80650;
    position: absolute;
    color:white;
    padding: 0.85em 0.75em;
    display: block;
    z-index: -1;
}

.slideButton>a[class^="slide"]::after {
    transition: all 0.35s;
}

.slideButton>a[class^="slide"]:hover::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.5s;
}

.slideButton>a.slide_from_left::after {
    top: 0;
    bottom: 0;
    left: -100%;
    right: 100%;
}