.emptySpace {
   height: 3rem;



}

.about-us-title {
   margin-bottom: 2rem;
   padding: 1rem;
   color: var(--color-white);
}

.about-section {
   min-height: 100vh;
   background-color: transparent;
   padding-top: 2rem;
   margin-top: 1rem;
   display: flex;
   flex-direction: column;
}

.about-section .row1 {
   display: grid;
   grid-template-columns: 1fr 1fr;
   align-items: center;
   justify-items: center;
   gap: 1rem;
}

.about-section .row1 .picture {
   width: 65%
}

.about-section .row1 .we-are {
   width: 90%;
   height: 100%;
   color: var(--color-white);
   justify-self: start;
   display: flex;
   flex-direction: column;
   gap: 1rem;
}

.about-section .row2 {
   display: flex;
   flex-direction: column;
   align-items: center;
   color: var(--color-white);
}
.row2 h2{
   width: 50%;
}
.about-section .row2 h1{
   font-size: 2rem;
   padding-left: 1.5rem;
   padding-right: 1.5rem;
}

.about-section .row2 .history-title {
   margin-top: 1rem;

}
.about-section .row2 .details{
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   
   width: 100%;
   gap: 1rem;
   justify-items: center;
   
   
}
.details .card h2{
   width: 100%;
   text-align: center;
}
.about-section .row2 .details .card{
   color: var(--color-black);
   width: 85%;
   height: 350px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   padding: 1rem;
   margin: 1rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1rem;
  transition: var(--transition);
  border-right: 5px solid #d2044d;
  background: var(--color-bg2);

}

.about-section .row2 .details .card:hover{
   transform: translateY(-5px);
}

/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {}

/************** MEDIA QUERRY (SMALL DEVICES) ************/
@media screen and (max-width:600px) {
   .about-section .row1 {
      grid-template-columns: 1fr;
   }
   .about-section .row1 .picture{
      display: none;
   }
   .about-section .row1 .we-are {
      justify-self: center;
   }
   .we-are h1{
      font-size: 2.3rem;
   }
   .about-section .row2 .details{
      grid-template-columns: 1fr;
   }
   .about-section .row2 h2{
      text-align: justify;
      padding: 1rem;
     width: 100%;
   }
}