.flip-card {
    transition: var(--transition);
    justify-self: center;
}

.flip-card:hover {
    transform: scale(1.04);


}

.client-img {
    border: 1px solid #d2044d;
}

.front {
    border-right: 5px solid #d2044d;
    background: greenyellow;
   
}

.back {
    border-left: 5px solid #d2044d;
}
.back .client h2{
    color: red;

}