.emptySpace {
   height: 0rem;

}
#partners {
  min-height: fit-content;
   width: 100vw;
   overflow: visible;
   display: flex;
   flex-direction: column;
  margin: 0 auto;
  background-image: url("../../assets/map2.jpg");
  background-size: cover;
  height: 100vh;
 

}


.partners_container {
   display: grid;
   grid-template-columns: 1fr;
   align-items: flex-start;
   

   

   height: fit-content;
  

}

.partners_container .picture {
   flex: 1;
   display: flex;
   margin-top: 1.7rem;


   align-items: flex-start;
   justify-content: center;



}

.picture .image {
   padding-top: 0;
   width: 500px;
   height: 500px;

}

.partners_container .details {
   flex: 1;
   background-color: transparent;
   padding: 1.5rem;
   padding-top: 0.2rem;
   height: fit-content;
   display: flex;
   flex-direction: column;
   align-items: center;

}

.partners_container .details .title {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   gap: 2rem;
   padding: 1rem;
   color: var(--color-black);
   background: rgba(244, 251, 254,0.7);
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   margin-bottom: 5rem;
   width: 40%;
   justify-self: center;
  


}

.partners_container .details p,
ul li {
   color: black;
   font-size: 1.5rem;
   margin-top: 1rem;

}

.partners_container .details ul {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 0.8rem;
   align-self: flex-start;
   width: 100%;
}

.partners_container .details ul li {
   height: 4rem;
   padding: 5px;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   background-color: rgba(231, 232, 233,0.6);
   transition: var(--transition);
   padding-top: 1rem;
   font-weight: 400;
   color: var(--color-black);
   display: inline;
   width: 95%;
   border-right: 5px solid var(--color-primary);





}

.partners_container .details ul li .icon {
   color: var(--color-primary);
   vertical-align: middle;
   
   display: inline;
}

.partners_container .details ul li:hover {
   transform: translateY(-5%);
}

.image {
   width: 100%;
   height: 100%;


}


/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
   .partners_container .picture {
      display: none;

   }
}

/************** MEDIA QUERRY (SMALL DEVICES) ************/
@media screen and (max-width:600px) {
   .partners_container  {
      height: fit-content;
   }

   .partners_container .picture {
      display: none;
   }
   .partners_container .details ul {
    
      grid-template-columns: 1fr;
      width: 100%;
      
      
   }
   .partners_container .details ul li {
      width: 100%;
   }
   .partners_container .details .title {
      margin-bottom: 0;
      width: 100%;
   }
   #partners {
      height: fit-content;
     background-image: none;
      align-content: start;
     
   }
   .emptySpace{
      display: none;
   }
   
}