.emptySpace {
    height: 3.2rem;

}

#portfolio {
    background-image: url("../../assets/portfolio1.jpg");
    background-size: cover;
       
    width: 100vw;
}

.portfolioSection {
    min-height: fit-content;


    display: flex;
    flex-direction: column;

    




}

.portfolio-wrapper {
    display: flex;
    flex-direction: row;
    backdrop-filter: blur(7px);


}

.portfolio-picture {
    display: flex;


    align-items: flex-start;
    justify-content: center;
    flex: 0.5;
    padding-top: 5%;
}

.portfolio_details {
    display: flex;
    flex-direction: column;
    flex: 1;



}

.portfolio_details .row1 {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem;
    color: var(--color-primary);
    margin-bottom: 2rem;


}

.portfolio_details .titleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    padding: 1rem;
    color: black;

    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
    width: 100%;
    height: fit-content;



}

.titleBox>h2 {
    color: var(--color-white);
}

.titleBox .title {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: start;
    color: var(--color-white);
}

.titleBox .title .icon {
    margin-top: 0.6rem;
}

.portfolio_details .row2 {
    display: grid;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    grid-template-columns: repeat(4, 1fr);

    margin-bottom: 1rem;
    justify-items: center;
}

.portfolio_details .row2 .client {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 300px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: var(--color-white);
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    padding: 1rem;
}

input[type="button"] {
    width: 100px;
    height: 40px;
    transition: var(--transition);
    border-radius: 10px;
    background: transparent;
    border: 1px solid gray;
}

input[type="button"]:hover {
    background-color: var(--color-primary);
}

.client .client-img {
    align-items: center;
    justify-items: center;
    height: 60%;
    width: 60%;
    border-radius: 50%;


    cursor: pointer;
    transition: var(--transition);

}

.client .client-desc {
    height: 60%;
    width: 100%;
    color: black;

}

.portfolio_details .row2 .client h2 {
    color: black;
    width: 100%;
    text-align: center;
}

.modal-info-style h1 {
    font-size: 2.3rem;
    color: var(--color-light);
}

/*
.client .client-img:hover {
    transform: scale(1.2);
}*/

/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
    .portfolio_details .row2 {
        grid-template-columns: repeat(3, 250px);
    }

    .portfolio_details .titleBox {
        width: 90%;
    }

    .emptySpace {
        display: none;
    }
}

/************** MEDIA QUERRY (SMALL DEVICES) ************/
@media screen and (max-width:600px) {
    .portfolio_details .row2 {
        column-gap: 0rem;

        grid-template-columns: 1fr;
        justify-items: end;

    }

    .row2 .client {

        height: 120px;
        width: 120px;
    }

    .portfolio_details .titleBox {
        width: 100%;
    }

    .row2 .client {
        justify-self: center;


    }
    #portfolio{
        background-image:none
    }
}