.banner {
    height: 4rem;
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    align-items: center;
    justify-items: start;
    gap: 0.1rem;

    margin-top: 0;
    background-color: rgba(14, 5, 41, 0.5);

}

/*
.email,
.phone,
.banner {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;


    z-index: 5;

}
*/
.banner .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 2em;

}

.banner .right .lang {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-self: flex-end;


}

.lang>img {
    height: 20px;
    aspect-ratio: 1/1;
    border: 1px solid transparent;
    z-index: 20;
    transition: var(--transition);
    border-radius: 50%;



}

.lang>img:hover {
    transform: scale(1.2);
    box-shadow: 0 0 20px 0 crimson, 0 20px 15px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;

}

.banner .email {

    flex: 4;
    width: fit-content;
    height: fit-content;



    display: flex;
    gap: 0.3rem;
    align-items: center;
    z-index: 5;



}

.banner .phone {
    flex: 4;
    width: fit-content;
    height: fit-content;



    display: flex;
    gap: 0.3rem;
    align-items: center;
    z-index: 5;




}

.header_Socials {


    width: fit-content;

    display: flex;
    flex-direction: row;

    gap: 1rem;
    margin: 0.5rem;
    z-index: 5;


}

#my-tooltip {
    z-index: 20;
}

/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
    .about_container {
        grid-template-columns: 1fr;
        gap: 5%;
    }

    .about_me {
        width: 50%;
        margin: 2rem auto;
    }

    p {
        padding: 1rem;
    }

}

/************** MEDIA QUERRY (SMALL DEVICES) ************/
@media screen and (max-width:600px) {
    .banner {
        grid-template-columns: 1fr  1fr;
       
        justify-items: flex-end;

    }

    .banner .right {

        flex-direction: column;
        gap: 0;
        justify-content: center;
        align-items: center;
        
       

    }

    .header_Socials {
        display: none;
    }

    .banner .right .lang {
        gap: 2rem;
    }

    .banner .phone {
        display: none;
    }

}