@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600&family=Reem+Kufi+Fun:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo+Play:wght@400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;

}

/* CSS variable */
:root {
    --color-bg: #0d022b;
    --color-bg2: #fef4f8;
    /*rgb(244, 251, 254);#2c2f47;/*#fef4f8;252741*/
    --color-bg3: #0d022b;
    --color-bg4: #fef4f8;
    --color-bg-variant: #0e2f5c;
    /*#1f1f38;*/
    /* #2c2c6c; */
    --color-primary: #d80650;
    --color-primary-variant: rgba(77, 181, 255, 0.4);

    --color-white: white;
    --color-black: black;
    --color-light: rgba(255, 255, 255, 0.6);
    --color-blue-lite: #f2f9fc;
    --transition: all 400ms ease;

    --container-width-ld: 95%;
    --container-width-md: 86%;
    --container-width-sd: 90%;

    --arabicFont-h1: "cairo";
    --arabicFont-h1-variant: "Reem+Kufi+Fun";


}

html {
    scroll-behavior: smooth;
}

/* Remove window scroll bar */
::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Barlow Condensed', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);


    /*background-image: url(../src/assets/bg-texture.png);*/

}


/**************** GENERAL STYLES ****************/
.container {
    width: var(--container-width-ld);
    margin: 0 auto;



}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;

}

h1 {
    font-size: 3rem;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 800;
}


.emptySpace {
    margin-top: 1rem;
    height: 1rem;

}

section>h2,
section>h5 {
    text-align: center;
    color: var(--color-light);

}

section>h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;

}

.text-light {
    color: var(--color-light);

}

a {
    color: var(--color-white);
    transition: var(--transition);
    padding: 2px;

}

a:hover {
    color: var(--color-primary);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;

    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);


}

.btn:hover {
    background: var(--color-primary);
    color: var(--color-bg);
    border-color: var(--color-primary);
}

.btn:disabled {
    background-color: rgb(212, 208, 200);
    color: rgb(128, 128, 128);
    border: 1px solid rgb(212, 208, 200);
    ;
}

.btn-primary {
    background: var(--color-white);
    color: var(--color-bg);
}

.btn-submit {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    background: transparent;

}

.btn-submit:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}


img {
    display: inline-block;
    width: 100%;

}

/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
    body {
        max-width: 100%;
    }


}

/************** MEDIA QUERRY (SMALL DEVICES) ************/
@media screen and (max-width:600px) {
    body {
        max-width: 100%;
    }

}