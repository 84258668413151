.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
  }
  
  .modal-main {
    position:fixed;
    background: var(--color-bg-variant);
    width: 40%;
    
    min-height: fit-content;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  

  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  /************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
  .about_container{
     grid-template-columns: 1fr;
     gap: 5%;
  }
  .about_me{
     width: 50%;
     margin: 2rem auto ;
  }
  p{
     padding: 1rem;
  }
 
 }
 
 /************** MEDIA QUERRY (SMALL DEVICES) ************/
 @media screen and (max-width:600px) {
  .modal-main {
    
    width: 90%;
 
    top:50%;
    
  }

 }