.emptySpace {
    height: 3.2rem;

}

#certificates {
    min-height: 100vh;
    background-image: url("../../assets/expert3.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    padding: 0;
    width: 100vw;
    

}

.experts_container {
    display: flex;
    flex-direction: column;

    
    width: 100%;
    
    height:auto;

}

.experts_container .picture {
    flex: 1;
    display: flex;
    margin-top: 0.5rem;


    align-items: flex-start;
    justify-content: center;





}

.experts_container .picture .image {
    margin-top: 5rem;
    width: 100%;
    transform: scaleY(1.2);



}

.experts_container .details {
    flex: 1;
    background-color: transparent;
    padding: 2rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.experts_container .details .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
    color: var(--color-white);
    background: transparent;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/


}

.experts_container .details p,
ul li {
    color: var(--color-white);
    font-size: 1.5rem;
    margin-top: 1rem;
    text-align: justify;
    width: 50%;
    align-self: center;

}

.experts_container .details ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;


}


.experts_container .details ul li {
    height: 4rem;
    padding: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: var(--transition);
    padding-top: 1rem;
    font-weight: 400;
    background: var(--color-bg4);
    border-right: 5px solid var(--color-primary);
    color: var(--color-black);
    width: 100%;




}

.experts_container .details ul li .icon {
    color: var(--color-primary);
    vertical-align: middle;
}

.experts_container .details ul li:hover {
    transform: translateY(-5%);

    background-color: var(--color-blue-lite);
    cursor: pointer;

}




/************** MEDIA QUERRY (MEDIUM DEVICES) ************/
@media screen and (max-width:1024px) {
    .experts_container .picture {
        display: none;
    }

    .experts_container .details ul {

        grid-template-columns: repeat(2, 1fr);

    }

}

/************** MEDIA QUERRY (SMALL DEVICES) ************/
@media screen and (max-width:600px) {
    #certificates {
        width: 90%;
        justify-content: center;
        background-image:none
    }
    .experts_container .picture {
        display: none;
    }
    .experts_container .details{
        padding: 0;
    }
    .experts_container .details ul {

        grid-template-columns: 1fr;
        

    }

    .experts_container .details .title h1 {
        font-size: 2.4rem;
    }
    .experts_container .details p{
        width: 100%;
    }

}